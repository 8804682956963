<template>
    <div
        class="coupons mt-4"
        :class="{ active: applyActive }"
    >
        <div
            class="coupon-heading d-flex"
            :class="{ np: isApplied }"
        >
            <p
                class="sm-sbold"
                @click="applyActive = !applyActive"
            >
                {{
                    !isApplied
                        ? $t('page.cart.couponLabel')
                        : $t('page.cart.couponAlreadyAddedLabel')
                }}
            </p>

            <chevron-down-icon
                v-if="!isApplied"
                class="icon"
                @click="applyActive = !applyActive"
            />
        </div>
        <div
            v-if="isApplied"
            class="coupon-applied d-flex"
        >
            <p class="sm-sbold">
                {{ isApplied.name ? isApplied.name : $t('page.cart.couponLabel') }}
            </p>
            <b-button
                variant="secondary"
                class="sm icon fill"
                :disabled="sending"
                @click="deactivateCoupon()"
            >
                <x-icon />
            </b-button>
        </div>
        <div
            v-if="!loading && !isApplied"
            class="apply-coupon mt-2"
        >
            <div class="actions">
                <div
                    v-click-outside="closeList"
                    class="input"
                    :class="{ active: listActive }"
                >
                    <form
                        autocomplete="off"
                        @submit.prevent=""
                    >
                        <input
                            id="coupon"
                            v-model="couponCode"
                            type="text"
                            name="coupon"
                            :placeholder="
                                filteredList.length > 0
                                    ? $t('page.cart.selectCouponPlaceholder')
                                    : $t('page.cart.couponPlaceholder')
                            "
                            @click="listActive = true"
                        >
                    </form>
                    <chevron-down-icon
                        v-if="filteredList.length > 0"
                        @click="listActive = !listActive"
                    />
                    <ul
                        v-if="filteredList.length > 0"
                        class="coupon-list"
                    >
                        <li
                            v-for="(coupon, index) in filteredList"
                            :key="coupon.code + '-' + index"
                            @click="selectCoupon(coupon.code)"
                        >
                            <p class="sm-sbold">
                                {{ coupon.name ? coupon.name : $t('page.cart.couponLabel') }}
                            </p>
                            <caption>
                                {{ coupon.code }}
                            </caption>
                        </li>
                    </ul>
                </div>
                <b-button
                    variant="primary"
                    :disabled="
                        sending ||
                            convertedCouponCode.length < 6 ||
                            convertedCouponCode.length > 24
                    "
                    @click="applyCouponCode"
                >
                    {{ $t('page.cart.couponButton') }}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    import ChevronDownIcon from 'vue-feather-icons/icons/ChevronDownIcon';
    import XIcon from 'vue-feather-icons/icons/XIcon';
    import { mapActions, mapState } from 'vuex';

    export default {
        name: 'CartSummaryCoupon',
        components: {
            XIcon,
            ChevronDownIcon,
        },
        props: {
            actionName: {
                type: String,
                default: 'Checkout',
            },
        },
        data() {
            return {
                applyActive: false,
                listActive: false,
                couponCode: '',
                convertedCouponCode: '',
                sending: false,
            };
        },
        computed: {
            ...mapState('cart', ['availableCoupons', 'appliedCoupon', 'loading']),
            ...mapState('checkout', ['summaryData']),
            filteredList() {
                let sourceList = [];
                if (this.actionName == 'Checkout') {
                    sourceList = this.availableCoupons;
                } else {
                    sourceList = this.summaryData.available_coupons;
                }
                return sourceList.filter((list) => {
                    return (
                        list.code.toLowerCase().includes(this.couponCode.toLowerCase()) ||
                        list.name.toLowerCase().includes(this.couponCode.toLowerCase())
                    );
                });
            },
            isApplied() {
                if (
                    this.actionName == 'Checkout' &&
                    this.appliedCoupon &&
                    this.appliedCoupon.valid
                ) {
                    return this.appliedCoupon;
                } else if (
                    this.actionName == 'Summar' &&
                    this.summaryData.applied_coupon &&
                    this.summaryData.applied_coupon.valid
                ) {
                    return this.summaryData.applied_coupon;
                }
                return false;
            },
        },
        methods: {
            ...mapActions('cart', {
                applyCouponForCart: 'applyCoupon',
                deactivateCouponForCart: 'deactivateCoupon',
            }),
            ...mapActions('checkout', {
                applyCouponForCheckout: 'applyCoupon',
                deactivateCouponForCheckout: 'deactivateCoupon',
            }),
            selectCoupon(code) {
                this.couponCode = code;
                this.listActive = false;
            },
            closeList() {
                this.listActive = false;
            },
            async deactivateCoupon() {
                this.sending = true;
                if (this.actionName == 'Checkout') {
                    await this.deactivateCouponForCart(this.appliedCoupon.code);
                } else {
                    await this.deactivateCouponForCheckout(
                        this.summaryData.applied_coupon.code
                    );
                }
                this.sending = false;
            },
            async applyCouponCode() {
                this.sending = true;
                if (this.actionName == 'Checkout') {
                    await this.applyCouponForCart(this.couponCode);
                } else {
                    await this.applyCouponForCheckout(this.couponCode);
                }
                this.couponCode = '';
                this.sending = false;
            },
            convertInput() {
                this.convertedCouponCode = this.couponCode
                    .replace(/\s\s+/g, ' ')
                    .toLowerCase();
            },
        },
        watch: {
            couponCode() {
                this.convertInput();
            },
        },
        directives: {
            ClickOutside,
        },
    };
</script>

<style scoped lang="scss">
.coupons {
  height: 72px;
  &.active {
    .apply-coupon {
      display: flex;
    }
    .icon {
      -webkit-transform: rotateX(180deg);
      transform: rotateX(180deg);
      transition: transform 200ms ease-in;
    }
  }
  .coupon-heading {
    align-items: center;
    gap: 0;
    p {
      color: $text;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
      letter-spacing: 2px;
      @include media-breakpoint-down(xm) {
        font-size: 12px;
      }
    }
    &.np {
      p {
        cursor: initial;
      }
    }
    svg {
      margin-left: 8px;
      cursor: pointer;
      width: 16px;
      height: 16px;
      transition: transform 200ms ease-in;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  .coupon-applied {
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $gray-thin;
    padding-top: 12px;
    p {
      color: $text;
    }
    .btn {
      margin: 0;
      padding: 0px 4px;
      height: 24px;
    }
  }

  .apply-coupon {
    display: none;
    width: 100%;
    @include media-breakpoint-down(md) {
      display: block;
      max-width: 400px;
    }
    .actions {
      display: flex;
      width: 100%;
      .input {
        position: relative;
        flex: 1;
        transition: max-height 200ms ease-in;
        &.active {
          .coupon-list {
            transition: max-height 200ms ease-in;
            max-height: 200px;
          }
        }
        input[type='text'] {
          width: 100%;
          padding: 8px 40px 8px 12px;
          border: 1px solid $primary-25;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          font-size: 15px;
          color: $gray-dark;
          line-height: 22px;
          @include media-breakpoint-down(xm) {
            padding: 4px 40px 4px 12px;
          }
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          -moz-appearance: textfield;
          &:focus-visible,
          &:hover {
            border: 1px solid $gray-dark !important;
            outline: none;
          }
        }
        svg {
          height: 20px;
          width: 20px;
          position: absolute;
          right: 8px;
          top: 10px;
          stroke: $gray-dark;
          cursor: pointer;
          @include media-breakpoint-down(xm) {
            top: 6px;
          }
        }
        .coupon-list {
          overflow-x: scroll;
          max-height: 0px;
          box-shadow: $shadow-menu;
          background-color: $white;
          li {
            padding: 6px 12px;
            cursor: pointer;
            @include media-breakpoint-up(md) {
              &:hover {
                background-color: $gray-thin;
              }
            }
            p {
              color: $text;
              line-height: 18px;
            }
            caption {
              color: $gray-dark;
              font-size: 10px;
              line-height: 12px;
              text-transform: uppercase;
            }
          }
        }
      }
      .btn {
        width: 80px;
        margin: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        @include media-breakpoint-down(xm) {
          height: 32px;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
</style>
