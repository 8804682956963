<template>
    <div class="summary">
        <div class="summary-heading">
            <p class="sbold">
                {{ $t('page.cart.summaryHeading') }}
            </p>
        </div>
        <div class="summary-content">
            <div class="summary-item">
                <p class="title sm-sbold">
                    {{ $t('page.cart.subTotalText') }}
                </p>
                <p
                    v-dompurify-html="formatPrice(total.sub_total)"
                    class="has-sup sm-sbold"
                >
                    Ron
                </p>
            </div>
            <div
                v-if="couponCode && couponCode.valid"
                class="summary-item"
            >
                <span class="d-flex">
                    <p class="title sm-sbold">{{ $t('page.cart.discountText') }}</p>
                </span>
                <p
                    v-dompurify-html="formatPrice(-Math.abs(couponCode.value))"
                    class="has-sup sm-sbold discount"
                >
                    Ron
                </p>
            </div>
            <div
                v-if="isRamburs"
                class="summary-item"
            >
                <p class="title sm-sbold">
                    {{ $t('page.summary.rambursFeeText') }}
                </p>
                <p
                    v-dompurify-html="formatPrice(summaryData.ramburs_fee)"
                    class="has-sup sm-sbold"
                >
                    Ron
                </p>
            </div>
            <div class="summary-item">
                <p class="title sm-sbold">
                    {{ $t('page.cart.costTransportText') }}
                </p>
                <p
                    v-dompurify-html="formatPrice(shippingCost)"
                    class="has-sup sm-sbold"
                >
                    Ron
                </p>
            </div>
            <div
                v-if="isExtraKm"
                class="summary-item"
            >
                <p class="title sm-sbold">
                    {{ $t('page.summary.extraKmText', {name: summaryData.shipping_method.name}) }}
                </p>
                <p
                    v-dompurify-html="formatPrice(summaryData.extra_km_cost)"
                    class="has-sup sm-sbold"
                >
                    Ron
                </p>
            </div>
            <div class="summary-item total">
                <p class="title sm-sbold">
                    {{ $t('page.cart.totalText') }}
                </p>
                <p
                    v-dompurify-html="formatPrice(calculateTotalCost)"
                    class="has-sup sm-sbold"
                >
                    Ron
                </p>
            </div>
            <div class="summary-item checkout">
                <b-button
                    variant="primary"
                    @click="goToPage(actionName)"
                >
                    {{ $t('page.cart.continueButton') }}
                </b-button>
            </div>
            <cart-summary-coupon :action-name="actionName" />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import { splitedPrice } from '@/services/getSplitedPrice';

    import CartSummaryCoupon from './CartSummaryCoupon';

    export default {
        components: { CartSummaryCoupon },
        name: 'CartSummary',
        props: {
            couponCode: {
                type: Object,
                default(){
                    return {};
                }
            },
            actionName: {
                type: String,
                default: 'Checkout',
            },
        },
        data() {
            return {
                shippingCost: 0,
            };
        },
        computed: {
            ...mapState('cart', ['total', 'products', 'vouchers']),
            ...mapState('checkout', [
                'shippingData',
                'selectedShipping',
                'summaryData',
            ]),
            ...mapState('user', ['deliveryData', 'billingData', 'companyData']),
            rambursFee() {
                if (this.actionName == 'Checkout') {
                    return 0;
                }
                return this.summaryData['ramburs_fee'];
            },
            calculateTotalCost() {
                let sum = 0;
                if (this.actionName == 'Checkout') {
                    let couponValue =  this.couponCode && this.couponCode.valid ? this.couponCode.value : 0;
                    sum =
                        this.total.sub_total +
                        this.shippingCost -
                        couponValue +
                        this.rambursFee;
                } else {
                    sum = this.summaryData.total_price;
                }

                return sum.toFixed(2);
            },
            isExtraKm() {
                if (this.actionName == 'Summar') {
                    return this.summaryData.extra_km_cost > 0;
                }
                return false;
            },
            isRamburs() {
                if (this.actionName == 'Summar') {
                    return this.summaryData.ramburs_fee > 0;
                }
                return false;
            },
        },
        methods: {
            formatPrice(price) {
                return splitedPrice(price);
            },
            goToPage(actionName) {
                if (actionName == 'Summar') {
                    this.$emit('addError', 'reset');
                    let permitedPaymentMethods = ['ramburs', 'online'];
                    let permitedShippingMethods = [
                        'fan-courier',
                        'sameday',
                        'predare-personala',
                        'email',
                    ];
                    let permitedPersonTypes = ['individual', 'legal'];
                    let permitedSameData = [true, false];

                    let deliveryMethodString = localStorage.getItem('deliveryMethod');
                    let deliveryMethod;

                    try {
                        deliveryMethod = JSON.parse(deliveryMethodString);
                    } catch (error) {
                        deliveryMethod = { code: 'error' };
                    }

                    let paymentType = localStorage.getItem('paymentType');
                    let personType = localStorage.getItem('personType');
                    let sameData = localStorage.getItem('sameData') == 'true';
                    let error = false;

                    if (this.deliveryData.length == 0 && deliveryMethod.code != 'email') {
                        this.$toast.open({
                            message: this.$t('notification.deliveryAddressMissing'),
                            type: 'error',
                            duration: 5000,
                        });
                        error = true;
                        this.$emit('addError', 'delivery');
                    }

                    if (personType == 'legal') {
                        if (this.companyData.length == 0) {
                            this.$toast.open({
                                message: this.$t('notification.companyAddressMissing'),
                                type: 'error',
                                duration: 5000,
                            });
                            error = true;
                            this.$emit('addError', 'billing');
                        }
                    } else {
                        if (this.billingData.length == 0 && !sameData) {
                            this.$toast.open({
                                message: this.$t('notification.billingAddressMissing'),
                                type: 'error',
                                duration: 5000,
                            });
                            error = true;
                            this.$emit('addError', 'billing');
                        }
                    }

                    if (!permitedShippingMethods.includes(deliveryMethod.code)) {
                        this.$toast.open({
                            message: this.$t('notification.deliveryMethodMissing'),
                            type: 'error',
                            duration: 5000,
                        });
                        error = true;
                        this.$emit('addError', 'deliveryMethod');
                    }
                    if (!permitedPaymentMethods.includes(paymentType)) {
                        this.$toast.open({
                            message: this.$t('notification.paymentTypeMissing'),
                            type: 'error',
                            duration: 5000,
                        });
                        error = true;
                        this.$emit('addError', 'paymentMethod');
                    }
                    if (!permitedPersonTypes.includes(personType)) {
                        this.$toast.open({
                            message: this.$t('notification.personTypeMissing'),
                            type: 'error',
                            duration: 5000,
                        });
                        error = true;
                        this.$emit('addError', 'billing');
                    }
                    if (!permitedSameData.includes(sameData)) {
                        this.$toast.open({
                            message: this.$t('notification.sameData'),
                            type: 'error',
                            duration: 5000,
                        });
                        error = true;
                        this.$emit('addError', 'billing');
                    }
                    if (deliveryMethod.code == 'email' && sameData) {
                        localStorage.setItem('sameData', false);
                    }

                    if (!error) {
                        this.$router.push({ name: actionName }).catch(() => {
                            //
                        });
                    }
                } else {
                    this.$router.push({ name: actionName }).catch(() => {
                        //
                    });
                }
            },
            calculateShippingCost() {
                let deliveryMethodString = localStorage.getItem('deliveryMethod');
                let deliveryMethod;

                try {
                    deliveryMethod = JSON.parse(deliveryMethodString);
                } catch (error) {
                    deliveryMethod = null;
                }

                let deliveryCost = this.selectedShipping.cost;
                if (deliveryMethodString && deliveryMethod) {
                    deliveryCost = deliveryMethod.cost;
                }
                if (this.actionName == 'Checkout') {
                    this.shippingCost = deliveryCost;
                } else {
                    this.shippingCost = this.summaryData.shipping_method.price;
                }
            },
        },
        mounted() {
            this.calculateShippingCost();
        },
        watch: {
            selectedShipping: {
                handler() {
                    this.calculateShippingCost();
                },
                deep: true,
            },
            total: {
                handler() {
                    this.calculateShippingCost();
                },
                deep: true,
            },
        },
    };
</script>

<style scoped lang="scss">
.summary {
  .summary-heading {
    padding-bottom: 12px;
    @include media-breakpoint-between(md, lg) {
      padding-top: 12px;
    }
    @include media-breakpoint-down(xm) {
      padding-bottom: 6px;
    }
    border-bottom: 1px solid $gray-thin;
    p {
      color: $text;
      text-transform: uppercase;
    }
  }
  .summary-content {
    .summary-item {
      padding: 10px 0px;
      display: flex;
      gap: 12px;
      justify-content: space-between;
      &:first-of-type {
        padding-top: 21px;
        @include media-breakpoint-down(xm) {
          padding-top: 14px;
        }
      }
      p {
        color: $text;
        &.title {
          text-transform: uppercase;
        }
        &.discount{
            color: $primary;
        }
        &:last-of-type{
            min-width: max-content;
        }
      }
      @include media-breakpoint-down(xm) {
        padding-top: 0px;
      }
      span {
        align-items: center;
        svg {
          cursor: pointer;
          width: 16px;
          height: 16px;
          stroke: $gray;
        }
      }
      &.total {
        margin-top: 8px;
        p {
          color: $primary;
        }
      }
      &.checkout {
        .btn {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}
</style>
